import axios from "axios";
import applyCaseMiddleware from 'axios-case-converter';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

/**
 * Models are converted automatically to snake case before sending to the python backend
 */

export interface FeedbackRequest {
    inputText: string, 
    outputText: string, 
    sourceLanguage: string,
    targetLanguage: string,
    rating: number,
    comment: string,
    feedbackType: string
}


export class FeedbackService {
    private static _classInstance: FeedbackService | null = null;
    public static get classInstance(): FeedbackService {
        if (FeedbackService._classInstance === null) {
            FeedbackService._classInstance = new FeedbackService();
        }
        return FeedbackService._classInstance;
    }

    readonly api = applyCaseMiddleware(axios.create({ baseURL: baseUrl }), { ignoreHeaders: true });



    public async feedback(feedbackRequest: FeedbackRequest) {
       
        const response = await this.api.post<string>("/feedback", feedbackRequest);
        console.log("response:", response);
    }

}


